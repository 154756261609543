import useSettings from "app/hooks/useSettings";

export default function MatxLogo({ className }) {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  // Utilisez l'image du logo du dossier public
  const logoPath = "/assets/images/logorhvs.png"; // Chemin relatif à partir du dossier public

  return (
    <img
      src={logoPath}
      alt="Logo"
      width="90px"
      height="70px"
      className={className}
    />
  );
}
