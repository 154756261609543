import { lazy } from "react";
import Loadable from "app/components/Loadable";

const AppForm = Loadable(lazy(() => import("./uploadPdf/AppForm")));
const ListTableSupplier = Loadable(lazy(() => import("./ListTableSupplier")));
const EditDocument = Loadable(lazy(() => import("./EditDocument")));
const AllTableSupplier = Loadable(lazy(() => import("./list/AllSupplier")));
const BillBySupplier = Loadable(lazy(() => import("./list/BillBySupplier")));
const UnpaidInvoices = Loadable(lazy(() => import("./list/BillUnpaid")));
const ShareBill = Loadable(lazy(() => import("./share/ShareBill")));
const ShareduserBill = Loadable(lazy(() => import("./share/ShareduserBill")));


const billRoutes = [
  { path: "/bill/upload", element: <AppForm /> },
  { path: "/bill/listbysupplier", element: <ListTableSupplier /> },
  { path: "/edit/:documentId", element: <EditDocument /> },
  { path: "/bill/listallsupplier", element: <AllTableSupplier /> },
  { path: "/bill/listbysupplier/:id", element: <BillBySupplier /> },
  { path: "/bill/unpaid", element: <UnpaidInvoices /> }, 
  { path: "/bill/share", element: <ShareBill /> },
  { path: "/bill/share/:uniqueId", element: <ShareduserBill /> }
];

export default billRoutes;
