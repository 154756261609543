import { lazy } from "react";
import Loadable from "app/components/Loadable";

const SupplierByCompany = Loadable(lazy(() => import("./SuppliersByCompany")));
const DocumentByCompany = Loadable(lazy(() => import("./RelaisRHVSDocuments")));
const paidDocuments = Loadable(lazy(() => import("./PaidRelaisRHVSDocuments")));

const companyId = "6687bf832dd5a96391e0ab6e";

const rhvsRoutes = [
  {
    path: "/rhvs/supplier",
    element: <SupplierByCompany companyId={companyId} />,
  },
  {
    path: "/rhvs/bycompanies",
    element: <DocumentByCompany companyId={companyId} />,
  },
  {
    path: "/rhvs/paid",
    element: <paidDocuments companyId={companyId} />,
  },
];

export default rhvsRoutes;
