import { lazy } from "react";
import Loadable from "app/components/Loadable";

const AdminSupplier = Loadable(lazy(() => import("./ManageSupplier")));
const AdminSupplierForm = Loadable(lazy(() => import("./ManageSupplierForm")));
const AdminCompany = Loadable(lazy(() => import("./CompanyTable")));
const AdminCurrency = Loadable(lazy(() => import("./CurrencyTable")));



const billRoutes = [
  { path: "/admin/manageSupplier", element: <AdminSupplier /> },
  { path: "/admin/manageSupplierForm", element: <AdminSupplierForm /> },
  { path: "/admin/manageCompany", element: <AdminCompany /> },
  { path: "/admin/manageCurrency", element: <AdminCurrency /> },


];

export default billRoutes;
