import { CircularProgress, Box, styled, keyframes } from "@mui/material";

// Animation pour faire tourner l'image
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// STYLED COMPONENT
const StyledLoading = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh", // Prend toute la hauteur de la vue
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default, // Utilise la couleur de fond du thème
}));

const StyledImage = styled("img")({
  width: 100, // Taille plus grande pour visibilité
  height: 80,
  marginBottom: 20, // Espacement entre l'image et le loader
  animation: `${rotate} 20s linear infinite`, // Applique l'animation de rotation
});

export default function Loading() {
  return (
    <StyledLoading>
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledImage src="/assets/images/logorhvs.png" alt="Logo" />
        <CircularProgress size={60} thickness={5} />{" "}
        {/* Augmente la taille et l'épaisseur du CircularProgress */}
      </Box>
    </StyledLoading>
  );
}
