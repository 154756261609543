import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack"; // Importation de SnackbarProvider

import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import routes from "./routes";
import "../fake-db";

export default function App() {
  console.log("L'application a été déployée avec succès !");

  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>{content}</SnackbarProvider>
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
